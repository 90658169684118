import { computed, observable, makeObservable } from "mobx";
import { Axios } from "../utilities/network";
import { isWeb } from "../utilities/platform";
import RootStore from "./root-store";
import { navigate } from "gatsby";
import { PAGE_SIZE, PendingInfo, UserInfo, VmList } from "../constants/options";
import { SetLocalStorage } from "../utilities/cryptography";
import { USER_INFO } from "../constants/storage-keys";

export default class UserStore {
  get isAuthed() {
    return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null && localStorage.WISHLIST != null;
  }

  set isAuthed(value) {
    console.log("Deleting token...");
    if (!value && isWeb) {
      localStorage.clear();
    }
  }

  loading: boolean = false;
  signingIn: boolean = false;
  userList: VmList = { totalCount: 0, items: [] };

  userToken: string | null = null;
  userInfo: any = null;
  numPendings: number | null = null;

  resetResponse: any = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      loading: observable,
      signingIn: observable,
      userToken: observable,
      userList: observable,
      userInfo: observable,
      numPendings: observable,
      resetResponse: observable,
    });
    this.rootStore = rootStore;
  }

  signIn = async (userModel: any) => {
    this.signingIn = true;
    try {
      const res = await Axios.post(`/api/Auth/SignIn`, userModel);
      const token = `Bearer ${res.data.token}`;
      const user = res.data;
      this.userToken = user.token;
      if (isWeb) {
        localStorage.USER_TOKEN = token;
        let userInfo: UserInfo = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          bankName: user.bankName,
          bankCode: user.bankCode,
          roleId: user.roleId,
        };
        this.numPendings = user.pendingBookingRequests + user.pendingInvoiceApprovals + user.pendingMaintenanceRequests;
        SetLocalStorage(USER_INFO, userInfo);
      }
      // console.log("User Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.signingIn = false;
    }
  };

  signOut = () => {
    this.isAuthed = false;
    this.userToken = null;
    navigate("/");
    this.rootStore.notify("Bye", "success");
  };

  signUp = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/SignUp`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getUserList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/UserList/${skip}/${take}`);
      this.userList = res.data;
      console.log("User List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getUserById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/User/${id}`);
      this.userInfo = res.data;
      console.log(`User${id}`, res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateUserById = async (id: number, req: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Auth/User/${id}`, req);
      this.userInfo = res.data;
      console.log(`User${id}`, res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getUserRoleCategory = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/RoleCategories/${skip}/${take}`);
      this.userList = res.data;
      console.log("Role Categories", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  // getPendings = async () => {
  //   this.loading = true;
  //   try {
  //     const res = await Axios.get(`/api/Dashboard/Pending`);
  //     this.numPendings = res.data.pendingBookingRequests + res.data.pendingInvoiceApprovals + res.data.pendingMaintenanceRequests;
  //     return Promise.resolve(res.data);
  //   } catch (err) {
  //     return Promise.reject(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // };

  getUserInfo = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/User`);
      console.log(res.data);
      this.userInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  resetPassword = async (newPassword: string) => {
    this.loading = true;
    try {
      let id = this.userInfo.id;
      const res = await Axios.post(`/api/Auth/User/Reset/Password/`, {id, password: newPassword});
      this.resetResponse = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  sendResetPasswordEmail = async (userEmail: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/User/Reset/Password/Email/${userEmail}`);
      this.resetResponse = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };
}
