
import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import OptionStore from './option-store';
import FarmStore from './farm-store';
import DatabaseStore from './database-store';

type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  optionStore: OptionStore,
  farmStore: FarmStore,
  databaseStore: DatabaseStore,
};

const isServer = typeof window === "undefined";
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    optionStore: rootStore.optionStore,
    farmStore: rootStore.farmStore,
    databaseStore: rootStore.databaseStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);
// @ts-ignore
export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
