import { BookingStatus, Language, Priority, UserInfo } from "../constants/options";
import { USER_INFO } from "../constants/storage-keys";
import useStores from "../hooks/use-stores";
import { GetLocalStorage } from "./cryptography";
import { isWeb } from "./platform";

export const IsAuthed = () => {
  return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null;
};

export const GetLanguage = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG : Language.ENGLISH;
};

export const GetLanguageLabel = () => {
  return (isWeb && localStorage.LANG) ? localStorage.LANG == Language.ENGLISH ? 'English'
    : localStorage.LANG == Language.ZH_HK ? '繁體中文 (香港)'
      : localStorage.LANG == Language.ZH_TW ? '繁體中文 (台灣)'
        : '简体中文' : 'English';
};

export const GetCurrency = () => {
  return (isWeb && localStorage.LANG && localStorage.LANG === Language.ZH_CN) ? '￥' : '$';
};

export const GetBookingStatus = (statusId: number) => {
  if (statusId == BookingStatus.REJECTED) return "Rejected";
  if (statusId == BookingStatus.APPROVED) return "Approved";
  if (statusId == BookingStatus.PENDING) return "Pending";
  if (statusId == BookingStatus.CANCELLED) return "Cancelled";
};

export const ConvertLangKeyToLabel = (lang: Language) => {
  return lang === Language.ENGLISH ? 'English' :
    lang === Language.ZH_CN ? '简体中文' :
      lang === Language.ZH_HK ? '繁體中文 (香港)'
        : '繁體中文 (台灣)';
};

export const GetUserProfile = () => {
  let userInfo: UserInfo = { id: 0, firstName: "", lastName: "" };
  if (isWeb && localStorage.USER_INFO) userInfo = GetLocalStorage(USER_INFO);
  return userInfo;
};


export const ConvertListToCommonOptions = (infoList: any[], fieldId: string, fieldLabel: string, extraField?: string) => {
  // @ts-ignore
  return infoList.map((info: any) => ({ id: info[fieldId], label: info[fieldLabel], extraField: info[extraField] }));
};

export const GetPriority = (priorityId: number) => {
  if (priorityId === Priority.LOW) return 'Low';
  if (priorityId === Priority.MEDIUM) return 'Medium';
  if (priorityId === Priority.HIGH) return 'High';
};

export const GetPriorityList = () => {
  return [
    { id: Priority.LOW, label: "Low" },
    { id: Priority.MEDIUM, label: "Medium" },
    { id: Priority.HIGH, label: "High" },
  ];
};

// export const ReducePendingNumber = () => {
//   const { userStore } = useStores();
//   userStore.getPendings();
// };

// export const Resident_Category_Id = 3;

export const CNDateTimeFormat = "YYYY-MM-DD HH:mm";
export const CNDateFormat = "YYYY-MM-DD";
export const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";
export const AUDDateFormat = "DD/MM/YYYY";
export const AUDTimeFormat = "hh:mm A";

// Fail Description
export const UPDATE_FAILED_MSG = "Update failed, please try again or contact admin";
export const DELETE_FAILED_MSG = "Delete failed, please try again or contact admin";
export const CREATE_FAILED_MSG = "Create failed, please try again or contact admin";
export const EMAIL_SEND_FAILED_MSG = "Email sent failed, please try again or contact admin";
export const PREVIEW_EMAIL_FAILED_MSG = "This is email is unable to preview, please try again or contact admin";