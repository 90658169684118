export const USER_INFO = "USER_INFO";
export const SUPPLIER_TYPE_OPTIONS = "SUPPLIER_TYPE_OPTIONS";
export const ASSET_OPTIONS = "ASSET_OPTIONS";
// export const ASSET_TYPE_OPTIONS = "ASSET_TYPE_OPTIONS";
export const JOB_AREA_OPTIONS = "JOB_AREA_OPTIONS";
export const JOB_SUB_AREA_OPTIONS = "JOB_SUB_AREA_OPTIONS";
export const CASE_CATEGORY_OPTIONS = "CASE_CATEGORY_OPTIONS";
export const CASE_MAIN_CATEGORY_OPTIONS = "CASE_MAIN_CATEGORY_OPTIONS";
export const CASE_STATUS_OPTIONS = "CASE_STATUS_OPTIONS";
export const APARTMENT_STATUS_OPTIONS = "APARTMENT_STATUS_OPTIONS";
export const RESIDENT_TYPE_OPTIONS = "RESIDENT_TYPE_OPTIONS";
export const APARTMENT_LEVEL_OPTIONS = "APARTMENT_LEVEL_OPTIONS";
export const BUILDING_AREA_OPTIONS = "BUILDING_AREA_OPTIONS";
export const METRE_TYPE_OPTIONS = "METRE_TYPE_OPTIONS";
export const ANNOUNCEMENT_TYPE_OPTIONS = "ANNOUNCEMENT_TYPE_OPTIONS";

//Editable Categories Start
export const SERVICE_CATEGORY = "SERVICE_CATEGORY";//Contractor category
export const ASSET_CATEGORY = "ASSET_CATEGORY";
export const JOB_AREA = "JOB_AREA";
export const CASE_CATEGORY = "CASE_CATEGORY";// Case type
export const CASE_STATUS = "CASE_STATUS";
export const APARTMENT_LEVEL = "APARTMENT_LEVEL";

//Editable Categories End
//Fixed Categories Start
export const APARTMENT_STATUS = "APARTMENT_STATUS";
export const APARTMENT_OWNER_CATEGORY = "APARTMENT_OWNER_CATEGORY";
export const APARTMENT_RESIDENT_CATEGORY = "APARTMENT_RESIDENT_CATEGORY";
//Fixed Categories End
// Service Supplier
export const SUPPLIERS = "SUPPLIERS";

export const REGION_CATEGORY_OPTIONS = "REGION_CATEGORY_OPTIONS";
export const CLIMATE_RISK_OPTIONS = "CLIMATE_RISK_OPTIONS";
export const AGRI_SYSTEM_OPTIONS = "AGRI_SYSTEM_OPTIONS";
export const STRESS_TEST_OPTIONS = "STRESS_TEST_OPTIONS";
export const DATABASE_TABLE_OPTIONS = "DATABASE_TABLE_OPTIONS";
export const ALL_FARM_NAMES = "ALL_FARM_NAMES";

// FOR KEEPING REGION AND AGRI SYSTEM INFO ACROSS PAGES
export const TEMP_REGION_AGRI_ACROSS_PAGE = "TEMP_REGION_AGRI_ACROSS_PAGE";

