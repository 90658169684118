import { computed, observable, makeObservable } from "mobx";
import { Axios } from "../utilities/network";
import { isWeb } from "../utilities/platform";
import RootStore from "./root-store";
import { navigate } from "gatsby";
import { PAGE_SIZE, UserInfo, VmList } from "../constants/options";
import { SetLocalStorage } from "../utilities/cryptography";
import { REGION_CATEGORY_OPTIONS, CLIMATE_RISK_OPTIONS, AGRI_SYSTEM_OPTIONS, STRESS_TEST_OPTIONS, DATABASE_TABLE_OPTIONS, ALL_FARM_NAMES } from "../constants/storage-keys";
import { ConvertListToCommonOptions } from "../utilities/general";

export default class OptionStore {
  loading: boolean = false;

  regionCategories: any[] = [];
  climateRiskCategories: VmList = { totalCount: 0, items: [] };
  agriSystemCategories: VmList = { totalCount: 0, items: [] };
  stressTestCategories: VmList = { totalCount: 0, items: [] };

  allFarmNames: any[] = [];
  databaseTables: any[] = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,

      regionCategories: observable,
      climateRiskCategories: observable,
      agriSystemCategories: observable,
      stressTestCategories: observable,
      databaseTables: observable,
    });
    this.rootStore = rootStore;
  }

  searchAgriSystemCategoryList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Categories/AgriSys/${skip}/${take}`);
      // this.agriSystemCategories = res.data;
      // { totalCount: 0, items: [] }
      this.agriSystemCategories = {
        totalCount: 0, 
        items: [
          { id: 9999, name: 'All Industries' },
          ...res.data.items
        ]
      };
      SetLocalStorage(AGRI_SYSTEM_OPTIONS, this.agriSystemCategories.items);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchClimateRiskCategoryList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Categories/ClimateRisk/${skip}/${take}`);
      this.climateRiskCategories = res.data;
      SetLocalStorage(CLIMATE_RISK_OPTIONS, res.data.items);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchRegionCategoryList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Categories/Region/${skip}/${take}`);
      this.regionCategories = [
        { id: 9999, name: 'All (Australia wide)' },
        ...res.data.items
      ];
      SetLocalStorage(REGION_CATEGORY_OPTIONS, this.regionCategories);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchStressTestCategoryList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Categories/StressTest/${skip}/${take}`);
      this.stressTestCategories = res.data;
      SetLocalStorage(STRESS_TEST_OPTIONS, res.data.items);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getAllDatabaseTables = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/FileToDb/Databases`);

      let dbOptions = res.data.items.map((db: any, index: number) => ({
        ...db,
        id: index + 1,
        name: db.dbName,
        data: db.tables.map((table: any, index: number) => ({
          ...table,
          id: index + 1,
          name: table.tableName,
          data: table
        })),
      }))

      this.databaseTables = dbOptions;

      SetLocalStorage(DATABASE_TABLE_OPTIONS, dbOptions);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getAllFarmNames = async () => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/SearchFarmInfoByRegionAgriAndSystem/`, {});
      const farmNamesList = res.data.items.map((farm: any) => ({ id: farm.farmId, name: farm.farmName, region: farm.regionId }))

      SetLocalStorage(ALL_FARM_NAMES, farmNamesList);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
