import { action, computed, observable, makeObservable } from "mobx";
import { Axios } from "../utilities/network";
import { isWeb } from "../utilities/platform";
import RootStore from "./root-store";
import { navigate } from "gatsby";
import { UploadSource } from "../constants/options";
import { FAxios } from "../utilities/file-network";
import { GHG_Emission_Score_Boundaries } from "../constants/fixed-numbers";

export default class FarmStore {
  loading: boolean = false;
  uploading: boolean = false;
  downloading: boolean = false;

  farmInfo: any = null;
  farmList: any = null;
  allFarmList: any = null; // PORTFOLIO

  cachedFarmList: { year: number, data: any[] }[] = [];

  farmEmissionsList: any = null;
  combinedEmissionsList: any = null;

  portfoliSectorAvgInfo: any = null;
  portfoliSectorSumInfo: any = null;

  farmRegionPortfolioAeData: any = null;
  farmRegionPortfolioPeiData: any = null;
  farmRegionPortfolioEeiData: any = null;
  farmRegionPortfolioWaciData: any = null;

  farmPhysicalRiskData: any = null;

  farmEmissionInfo: any = null;
  attachmentList: any = null;
  attachmentInfo: any = null;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      uploading: observable,
      downloading: observable,
      farmEmissionInfo: observable,
      farmInfo: observable,
      farmList: observable,
      allFarmList: observable,

      cachedFarmList: observable,

      attachmentInfo: observable,
      attachmentList: observable,

      portfoliSectorAvgInfo: observable,
      portfoliSectorSumInfo: observable,

      farmRegionPortfolioAeData: observable,
      farmRegionPortfolioPeiData: observable,
      farmRegionPortfolioEeiData: observable,
      farmRegionPortfolioWaciData: observable,

      farmPhysicalRiskData: observable,

      getAEFarmPortfolioRegionPredictions: action,
      getEEIFarmPortfolioRegionPredictions: action,
      getPEIFarmPortfolioRegionPredictions: action,
      getWACIFarmPortfolioRegionPredictions: action,

      getFarmPhysicalRiskData: action,

      getFarmTotalEmission: action,
      uploadAttachment: action,
      getAttachmentList: action,
      getPortfoliSectorStatAverage: action,
      getPortfoliSectorStatSum: action,
      getAttachment: action,
      getFarmById: action,
      getFarmList: action,
      getAllFarmList: action,
      uploadAttachmentToDb: action,
    });
    this.rootStore = rootStore;
  }

  getFarmTotalEmission = async (farmId: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTSCore/CalculateTotalEmissionForSingleFarm/${farmId}`);
      this.farmEmissionInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  uploadAttachment = async (files: UploadSource[]) => {
    this.uploading = true;
    try {
      let apiLink = `/api/File/Upload`;
      const res = await Axios.post(apiLink, files);
      this.attachmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  getAttachmentList = async (skip: number, take: number) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/FileList`);
      this.attachmentList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getAttachment = async (id: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Wollemi_UTS_Calculate/FileDownload/?id=${id}`, { responseType: "blob" });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  getFarmById = async (farmId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetSingelFarmDataByIdAndRange/${farmId}?startYear=${startYear}&endYear=${startYear}`);

      this.farmInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getFarmList = async ({ regionId, agriSysId, startYear }: { regionId?: number, agriSysId?: number, startYear?: string }) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/SearchFarmInfoByRegionAgriAndSystem/`, {
        regionId: regionId === 9999 || regionId === -1 ? null : regionId,
        agriSystemId: agriSysId === 9999 || agriSysId === -1 ? null : agriSysId,
        startYear: startYear,
        endYear: startYear,
      });
      this.farmList = res.data;

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getAllFarmList = async (year: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/SearchFarmInfoByRegionAgriAndSystem/`, { startYear: year, endYear: year });

      if (this.cachedFarmList.map(cache => cache.year).includes(year)) {
        this.cachedFarmList = this.cachedFarmList.map((cache: any) => ({ ...cache, data: cache.year === year ? res.data.items : cache.data }))
      } else {
        this.cachedFarmList.push({ year: year, data: res.data.items })
      }

      this.allFarmList = res.data;

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  uploadAttachmentToDbWithMapping = async (file: UploadSource, maps: any) => {
    this.uploading = true;
    try {
      let apiLink = `/api/FileToDb/Upload`;
      let req = {
        file,
        maps,
      };
      const res = await Axios.post(apiLink, req);
      this.attachmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  uploadAttachmentToDb = async (file: any) => {
    this.uploading = true;
    try {
      const res = await FAxios.post(`/api/Wollemi_UTS_Calculate/UploadFile`, file);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  // FOR ASSET PAGE TOP RIGHT TABLE
  getPortfoliSectorStatAverage = async (regionId: number, agriSysId: number, startYear: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/GetProtfolioSectorDataByRange/Average/`, {
        regionId: regionId === 9999 || regionId === -1 ? null : regionId,
        agriSystemId: agriSysId === 9999 || agriSysId === -1 ? null : agriSysId,
        startYear: +startYear,
        endYear: +startYear
      });
      this.portfoliSectorAvgInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  // FOR ASSET PAGE TOP RIGHT TABLE
  getPortfoliSectorStatSum = async (regionId: number, agriSysId: number, startYear: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/GetProtfolioSectorDataByRange/Sum/`, {
        regionId: regionId === 9999 || regionId === -1 ? null : regionId,
        agriSystemId: agriSysId === 9999 || agriSysId === -1 ? null : agriSysId,
        startYear: +startYear,
        endYear: +startYear
      });

      this.portfoliSectorSumInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  // API FOR GETTING STRESSTEST DATA WITH YEARS
  getAEPredictions = async (regionId: number, agriSystemId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Wollemi_UTS_Calculate/GetAePredictionsByRegionAgriAndSystem/`, {
        regionId: regionId === 9999 || regionId === -1 ? null : regionId,
        agriSystemId: agriSystemId === 9999 || agriSystemId === -1 ? null : agriSystemId,
        startYear,
        endYear: startYear,
      });

      this.farmEmissionsList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getAEFarmPortfolioRegionPredictions = async (farmId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetFarmRegionAndProtfolio/AE/${farmId}?startYear=${startYear}&endYear=${startYear}`);
      this.farmRegionPortfolioAeData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getEEIFarmPortfolioRegionPredictions = async (farmId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetFarmRegionAndProtfolio/EEI/${farmId}?startYear=${startYear}&endYear=${startYear}`);
      this.farmRegionPortfolioEeiData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getPEIFarmPortfolioRegionPredictions = async (farmId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetFarmRegionAndProtfolio/PEI/${farmId}?startYear=${startYear}&endYear=${startYear}`);
      this.farmRegionPortfolioPeiData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  getWACIFarmPortfolioRegionPredictions = async (farmId: number, startYear: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetFarmRegionAndProtfolio/WACI/${farmId}?startYear=${startYear}&endYear=${startYear}`);
      this.farmRegionPortfolioWaciData = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  // TODO: CONNECT THIS TO BACKEND
  // FOR ASSET PAGE TOP RIGHT TABLE
  getFarmPhysicalRiskData = async (farmId: number, startYear: any) => {
    this.loading = true;
    try {

      // const res = await Axios.get(`/api/Wollemi_UTS_Calculate/GetProtfolioSectorDataByRange/Average/${farmId}?startYear=${startYear}&endYear=${startYear}`);
      // this.portfoliSectorAvgInfo = res.data;
      // return Promise.resolve(res.data);

      const res = {
        data: {
          disorderly: [
            {
              year: 2022,
              overall: { riskFlag: 1, cvar: .15 },
              rain: { riskFlag: 1, cvar: .19 },
              heat: { riskFlag: 1, cvar: .12 },
              frost: { riskFlag: 0, cvar: .09 },
              fire: { riskFlag: 0, cvar: .09 },
              wind: { riskFlag: 0, cvar: .02 },
              flood: { riskFlag: 0, cvar: .05 },
            },
            {
              year: 2030,
              overall: { riskFlag: 1, cvar: .19 },
              rain: { riskFlag: 2, cvar: .25 },
              heat: { riskFlag: 1, cvar: .14 },
              frost: { riskFlag: 1, cvar: .11 },
              fire: { riskFlag: 1, cvar: .12 },
              wind: { riskFlag: 0, cvar: .03 },
              flood: { riskFlag: 0, cvar: .07 },
            },
            {
              year: 2040,
              overall: { riskFlag: 2, cvar: .25 },
              rain: { riskFlag: 2, cvar: .29 },
              heat: { riskFlag: 2, cvar: .21 },
              frost: { riskFlag: 1, cvar: .13 },
              fire: { riskFlag: 1, cvar: .19 },
              wind: { riskFlag: 0, cvar: .05 },
              flood: { riskFlag: 0, cvar: .09 },
            },
            {
              year: 2050,
              overall: { riskFlag: 2, cvar: .33 },
              rain: { riskFlag: 2, cvar: .35 },
              heat: { riskFlag: 2, cvar: .27 },
              frost: { riskFlag: 1, cvar: .13 },
              fire: { riskFlag: 2, cvar: .25 },
              wind: { riskFlag: 0, cvar: .05 },
              flood: { riskFlag: 0, cvar: .09 },
            },
          ],
          hothouse: [
            {
              year: 2022,
              overall: { riskFlag: 1, cvar: .15 },
              rain: { riskFlag: 1, cvar: .19 },
              heat: { riskFlag: 1, cvar: .12 },
              frost: { riskFlag: 0, cvar: .09 },
              fire: { riskFlag: 0, cvar: .09 },
              wind: { riskFlag: 0, cvar: .02 },
              flood: { riskFlag: 0, cvar: .05 },
            },
            {
              year: 2030,
              overall: { riskFlag: 2, cvar: .24 },
              rain: { riskFlag: 2, cvar: .27 },
              heat: { riskFlag: 1, cvar: .19 },
              frost: { riskFlag: 1, cvar: .12 },
              fire: { riskFlag: 1, cvar: .15 },
              wind: { riskFlag: 0, cvar: .03 },
              flood: { riskFlag: 0, cvar: .07 },
            },
            {
              year: 2040,
              overall: { riskFlag: 2, cvar: .31 },
              rain: { riskFlag: 2, cvar: .30 },
              heat: { riskFlag: 2, cvar: .26 },
              frost: { riskFlag: 1, cvar: .14 },
              fire: { riskFlag: 2, cvar: .21 },
              wind: { riskFlag: 0, cvar: .05 },
              flood: { riskFlag: 0, cvar: .09 },
            },
            {
              year: 2050,
              overall: { riskFlag: 2, cvar: .37 },
              rain: { riskFlag: 2, cvar: .40 },
              heat: { riskFlag: 2, cvar: .29 },
              frost: { riskFlag: 1, cvar: .14 },
              fire: { riskFlag: 2, cvar: .28 },
              wind: { riskFlag: 0, cvar: .05 },
              flood: { riskFlag: 0, cvar: .09 },
            },
          ]
        }
      };

      this.farmPhysicalRiskData = res.data;
      return Promise.resolve(res.data);

    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };
}
