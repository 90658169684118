import { computed, observable, makeObservable } from "mobx";
import { Axios } from "../utilities/network";
import { isWeb } from "../utilities/platform";
import RootStore from "./root-store";
import { navigate } from "gatsby";
import { AttachmentType, PAGE_SIZE, UploadSource, UserInfo, VmList } from "../constants/options";
import { SetLocalStorage } from "../utilities/cryptography";
import { USER_INFO } from "../constants/storage-keys";
import { FAxios } from "../utilities/file-network";

export default class DatabaseStore {
  loading: boolean = false;
  loadingInfo: boolean = false;

  dbTableColumns: any[] = [];

  invoices: VmList = { totalCount: 0, items: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingInfo: observable,
      dbTableColumns: observable,
      invoices: observable,
    });
    this.rootStore = rootStore;
  }

  getColumnsByDBTable = async (dbModleName: string, tableClassName: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/FileToDb/Prop/${dbModleName}/${tableClassName}/`);
      let colsWithID = res.data.items.filter((col: any) => col.className !== "ModifyByFileId").map((col: any, colIndex: number) => ({ id: colIndex + 1, ...col }));
      // console.log("colsWithID", [...colsWithID])
      this.dbTableColumns = colsWithID;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };
}
