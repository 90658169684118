import { OverridableComponent } from "@mui/material/OverridableComponent";
import useStores from "../hooks/use-stores";
import { SvgIconTypeMap } from "@mui/material";
import { LatLng } from "leaflet";

export const PAGE_SIZE = 10;

export const DEFAULT_COLOUR_INDICATOR = "#9ca3af";

export enum Language {
  ENGLISH = "en-AU",
  ZH_CN = "zh-CN",
  ZH_TW = "zh-TW",
  ZH_HK = "zh-HK",
}

export interface VmList {
  totalCount: number;
  items: any[];
}

export interface VmOption {
  id: number;
  label: string | number;
  value?: any;
  disabled?: boolean;
}
export interface VmTab {
  key: string | number;
  label: string | number;
  color?: string;
  icon?: any;
}

export enum ColorScheme {
  INITIAL,
  ERROR,
  WARNING,
  SUCCESS,
  INFO,
}

// ================ VM Dynamic Fields ================
export enum FieldType {
  TEXT,
  RICH_TEXT,
  NUMBER,
  TEXT_AREA,
  DATE,
  DATE_TIME,
  TIME,
  SELECT,
  CHECKBOX,
  RADIO,
  EMPTY,
}

export interface VmDynamicField {
  id: number;
  fieldType: FieldType;
  name: string;
  placeHolder?: string;
  value?: string | number | boolean;
  colSpan?: number;
  rows?: number;
  // selections only for select & radio buttons
  selections?: VmOption[];
  selectionsCols?: number;
  error?: boolean;
  autoWidth?: boolean;
  width?: string;
  disabled?: boolean;
  minRows?: number;
}
// ================ End VM Dynamic Fields ================
// ================  VM Smart Table ================
export interface VmTBody {
  type: VmTBodyType;
  fields:
    | string[]
    | VmTableButton[]
    | {
        compareBy: any;
        compareTo: any;
        operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&";
        true:
          | string[]
          | VmTableButton[]
          | {
              compareBy: any;
              compareTo: any;
              operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&";
              true: string[];
              false: string[];
              enableIndicator: boolean;
            };
        false:
          | string[]
          | VmTableButton[]
          | {
              compareBy: any;
              compareTo: any;
              operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&";
              true: string[];
              false: string[];
              enableIndicator: boolean;
            };
      }
    | {
        fields: string[];
        onClick: (req: any, event: any) => any;
      };
}
export enum VmTBodyType {
  NO,
  TEXT,
  NUMBER,
  COMPLEX,
  DESCRIPTION,
  CURRENCY,
  DATE,
  DATE_TIME,
  TIME,
  BUTTON,
  TEXT_LINK,
  TEXT_BUTTON,
  PRIORITY,
  CONDITION,
  BOOLEAN,
  PERCENTAGE,
}
export interface VmTableSearch {
  searchFields: string[];
  title: string;
  placeHolder?: string;
}
export interface VmTableButton {
  label: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | any;
  onClick: (req: any, event: any) => any;
  colorScheme?: ColorScheme;
}
// ================ End VM Smart Table ================

export interface MultiLangField {
  enField: string;
  cnField: string;
  hkField: string;
  twField: string;
}
export const LangList = [Language.ENGLISH, Language.ZH_CN, Language.ZH_HK, Language.ZH_TW];

export const States = ["NSW", "ACT", "NT", "QLD", "SA", "VIC", "TAS", "WA"];

// ================ User ================
export interface UserInfo {
  id: number;
  firstName: string;
  lastName: string;
  email?: string;
  bankName?: string;
  bankCode?: string;
  roleId?: string;
}

export interface PendingInfo {
  pendingBookingRequests: number;
  pendingInvoiceApprovals: number;
  pendingMaintenanceRequests: number;
  total: number;
}
// ================ End User ================
/**
 * Attachment Type
 * Use this type when uploading files
 * Therefore backend know which type of file is uploading
 */
export enum AttachmentType {
  IMAGE = 1,
  DOCUMENT = 2,
  WARRANTY = 3,
  INSURANCE = 4,
  INVOICE = 5,
  QUOTE = 6,
  QUOTE_REQUEST = 7,
  JOB_ORDER = 8,
  REPORT = 9,
}

export enum ImageSource {
  ASSET,
  CASE,
  SECURITY_LOG,
  MAINTENANCE,
  EVENT,
  MAINTENANCE_REQUEST,
}

/**
 * Use for component swiper
 */
export interface Image {
  id: number;
  localImgUrl?: string;
  formData?: UploadSource;
}

/**
 * When uploading file, we need to send this the request body in respecting to structure of this interface
 * Filename is the exact name of the file u are trying to upload with
 * Expiry date - only use for insurance file
 * Actual file, it is a BASE64 STRING! Convert it to BASE64 before uploading
 */
export interface UploadSource {
  fileName: string;
  expiryDate?: string;
  dueDate?: string;
  amount?: number;
  file: string;
  serviceSupplierId?: number;
}

/**
 * Priority used in cases & maintenance requests
 */
export enum Priority {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

/**
 * Event Calendar List
 */
export interface CalendarEvent {
  title: string;
  date: string;
  output: any;
}

export enum CalendarNavigateAction {
  PREV,
  NEXT,
  TODAY,
}

/**
 * Case Status
 */
export enum CaseStatus {
  NEW = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
}

/**
 * Facility Booking
 */
export enum BookingStatus {
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
  CANCELLED = 4,
  COMPLETED = 5,
}

export enum BookingBy {
  BM = 0,
  COMMUNITY = 1,
}

/**
 * Utility
 */

export enum Period {
  ALL = "All",
  MONTHLY = "Monthly",
  QUARTERLY = "Quarterly",
  HALF_YEAR = "Half-Yearly",
}

export const MonthlyOptions: VmOption[] = [
  { id: 1, label: "Jan" },
  { id: 2, label: "Feb" },
  { id: 3, label: "Mar" },
  { id: 4, label: "Apr" },
  { id: 5, label: "May" },
  { id: 6, label: "Jun" },
  { id: 7, label: "Jul" },
  { id: 8, label: "Aug" },
  { id: 9, label: "Sep" },
  { id: 10, label: "Oct" },
  { id: 11, label: "Nov" },
  { id: 12, label: "Dec" },
];

export const QuarterlyOptions: VmOption[] = [
  { id: 1, label: "Q1" },
  { id: 2, label: "Q2" },
  { id: 3, label: "Q3" },
  { id: 4, label: "Q4" },
];

export const HalfYearlyOptions: VmOption[] = [
  { id: 1, label: "First-Half" },
  { id: 2, label: "Second-Half" },
];

/**
 * Invoice
 */
export enum InvoiceParent {
  CASE = 1,
  MAINTENANCE = 2,
  EVENT = 3,
}

/**
 * Email
 */
export enum EmailType {
  ALL = 0,
  GENERAL = 1,
  QUOTE_REQUEST = 2,
  WORK_ORDER = 3,
}


/**
 * Dashboard Bar Chart
 */
export enum EmissionType {
  AE = 0,
  EEI = 1,
  PEI = 2,
  WACI = 3,
}


/**
 * StressTestLevel
 */

export enum StressTestType {
  Current = 0,
  Disorderly = 1,
  HotHouse = 2,
}