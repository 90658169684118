import "./src/styles/global.css";
import React, { useEffect } from "react";
import NotificationProvider from "./src/components/root/notification";
import { SnackbarProvider } from "notistack";

export const wrapRootElement = ({ element, props }) => {
  return (
    <SnackbarProvider>
      <NotificationProvider>
        <div>{element}</div>
      </NotificationProvider>
    </SnackbarProvider>
  );
};
