
import { observable, action, makeObservable } from 'mobx';
import UserStore from './user-store';
import OptionStore from './option-store';
import FarmStore from './farm-store';
import DatabaseStore from "./database-store";

interface WebNotification {
  message: string;
  options?: {
    variant: "success" | "error" | "info" | "warning";
  };
}

export default class RootStore {
  testVal = "TEST_VALUE";

  destination = "Select";

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info",
    },
  };

  userStore;

  optionStore;

  farmStore;

  databaseStore;

  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);
    this.farmStore = new FarmStore(this);
    this.optionStore = new OptionStore(this);
    this.databaseStore = new DatabaseStore(this);
  }

  notify(msg: string, level?: "success" | "error" | "info" | "warning") {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
