exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activation-index-tsx": () => import("./../../../src/pages/activation/index.tsx" /* webpackChunkName: "component---src-pages-activation-index-tsx" */),
  "component---src-pages-dashboard-farm-index-tsx": () => import("./../../../src/pages/dashboard/farm/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-farm-index-tsx" */),
  "component---src-pages-dashboard-index-old-tsx": () => import("./../../../src/pages/dashboard/index - old.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-old-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-index-tsx": () => import("./../../../src/pages/landing-page/index.tsx" /* webpackChunkName: "component---src-pages-landing-page-index-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */)
}

